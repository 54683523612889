import PerfectScrollbar from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { toggleSidebar } from "../../store/themeConfigSlice";
import AnimateHeight from "react-animate-height";
import { IRootState } from "../../store";
import { useState, useEffect } from "react";
import IconCaretsDown from "../Icon/IconCaretsDown";
import IconCaretDown from "../Icon/IconCaretDown";
import IconMenuDashboard from "../Icon/Menu/IconMenuDashboard";
import IconMinus from "../Icon/IconMinus";
import IconMenuCharts from "../Icon/Menu/IconMenuCharts";
import IconMenuDocumentation from "../Icon/Menu/IconMenuDocumentation";
import IconOpenBook from "../Icon/IconOpenBook";
import IconUsersGroup from "../Icon/IconUsersGroup";
import IconUser from "../Icon/IconUser";
import IconNotesEdit from "../Icon/IconNotesEdit";
import IconFolder from "../Icon/IconFolder";
import IconChartSquare from "../Icon/IconChartSquare";

const Sidebar = () => {
    const userRole = useSelector((state: IRootState) => state.userConfig.role);

    const [currentMenu, setCurrentMenu] = useState<string>("");
    const themeConfig = useSelector((state: IRootState) => state.themeConfig);
    const semidark = useSelector((state: IRootState) => state.themeConfig.semidark);
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const toggleMenu = (value: string) => {
        setCurrentMenu((oldValue) => {
            return oldValue === value ? "" : value;
        });
    };

    useEffect(() => {
        const selector = document.querySelector('.sidebar ul a[href="' + window.location.pathname + '"]');
        if (selector) {
            selector.classList.add("active");
            const ul: any = selector.closest("ul.sub-menu");
            if (ul) {
                let ele: any = ul.closest("li.menu").querySelectorAll(".nav-link") || [];
                if (ele.length) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click();
                    });
                }
            }
        }
    }, []);

    useEffect(() => {
        if (window.innerWidth < 1024 && themeConfig.sidebar) {
            dispatch(toggleSidebar());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <div className={semidark ? "dark" : ""}>
            <nav
                className={`sidebar fixed min-h-screen h-full top-0 bottom-0 w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] z-50 transition-all duration-300 ${
                    semidark ? "text-white-dark" : ""
                }`}
            >
                <div className="bg-white dark:bg-black h-full">
                    <div className="flex justify-between items-center px-4 py-3">
                        <NavLink to="/" className="main-logo flex items-center shrink-0">
                            <img
                                className="w-8 ml-[5px] flex-none dark:bg-white dark:rounded-full"
                                src="/assets/images/logo-redonda-small.png"
                                alt="logo"
                            />
                            <span className="text-2xl ltr:ml-1.5 rtl:mr-1.5 font-semibold align-middle lg:inline dark:text-white-light">
                                {t("name_app")}
                            </span>
                        </NavLink>

                        <button
                            type="button"
                            className="collapse-icon w-8 h-8 rounded-full flex items-center hover:bg-gray-500/10 dark:hover:bg-dark-light/10 dark:text-white-light transition duration-300 rtl:rotate-180"
                            onClick={() => dispatch(toggleSidebar())}
                        >
                            <IconCaretsDown className="m-auto rotate-90" />
                        </button>
                    </div>
                    <PerfectScrollbar className="h-[calc(100vh-80px)] relative">
                        <ul className="relative font-semibold space-y-0.5 p-4 py-0">
                            <li className="nav-item">
                                <NavLink to="/" className="group">
                                    <div className="flex items-center">
                                        <IconMenuDashboard className="group-hover:!text-primary shrink-0" />
                                        <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                                            {t("dashboard")}
                                        </span>
                                    </div>
                                </NavLink>
                            </li>

                            <h2 className="py-3 px-7 flex items-center uppercase font-extrabold bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08] -mx-4 mb-1">
                                <IconMinus className="w-4 h-5 flex-none hidden" />
                                <span>{t("apps")}</span>
                            </h2>

                            {/* Application Menu */}
                            <li className="nav-item">
                                <ul>
                                    <li className="nav-item">
                                        <NavLink to="/apps/create_test" className="group">
                                            <div className="flex items-center">
                                                <IconNotesEdit className="group-hover:!text-primary shrink-0" />
                                                <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                                                    {t("create_test")}
                                                </span>
                                            </div>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/apps/previous_tests" className="group">
                                            <div className="flex items-center">
                                                <IconFolder className="group-hover:!text-primary shrink-0" />
                                                <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                                                    {t("previous_tests")}
                                                </span>
                                            </div>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>

                            {/* Admin Menu */}
                            {userRole === 1 && (
                                <>
                                    <h2 className="py-3 px-7 flex items-center uppercase font-extrabold bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08] -mx-4 mb-1">
                                        <IconMinus className="w-4 h-5 flex-none hidden" />
                                        <span>{t("admin_access")}</span>
                                    </h2>
                                    <li className="menu nav-item">
                                        <NavLink to="/admin/users" className="group">
                                            <div className="flex items-center">
                                                <IconUsersGroup className="group-hover:!text-primary shrink-0" />
                                                <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                                                    {t("users")}
                                                </span>
                                            </div>
                                        </NavLink>
                                    </li>

                                    <li className="menu nav-item">
                                        <button
                                            type="button"
                                            className={`${
                                                currentMenu === "questions" ? "active" : ""
                                            } nav-link group w-full`}
                                            onClick={() => toggleMenu("questions")}
                                        >
                                            <div className="flex items-center">
                                                <IconOpenBook className="group-hover:!text-primary shrink-0" />
                                                <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                                                    {t("questions")}
                                                </span>
                                            </div>

                                            <div
                                                className={
                                                    currentMenu !== "questions" ? "rtl:rotate-90 -rotate-90" : ""
                                                }
                                            >
                                                <IconCaretDown />
                                            </div>
                                        </button>

                                        <AnimateHeight duration={300} height={currentMenu === "questions" ? "auto" : 0}>
                                            <ul className="sub-menu text-gray-500">
                                                <li>
                                                    <NavLink to="/admin/questions/category">{t("category")}</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/admin/questions/questions">{t("questions")}</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/admin/questions/answers">{t("answers")}</NavLink>
                                                </li>
                                            </ul>
                                        </AnimateHeight>
                                    </li>

                                    <li className="menu nav-item">
                                        <NavLink to="#" className="group">
                                            <div className="flex items-center">
                                                <IconChartSquare className="group-hover:!text-primary shrink-0" />
                                                <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                                                    {t("reports")}
                                                </span>
                                                <span className="badge relative -top-2 bg-black p-0.5 px-1.5 ml-1 rounded-full">
                                                    Dev
                                                </span>
                                            </div>
                                        </NavLink>
                                    </li>
                                </>
                            )}

                            {/* Reports Menu */}
                            <h2 className="py-3 px-7 flex items-center uppercase font-extrabold bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08] -mx-4 mb-1">
                                <IconMinus className="w-4 h-5 flex-none hidden" />
                                <span>{t("reports")}</span>
                            </h2>
                            <li className="menu nav-item">
                                <button
                                    type="button"
                                    className={`${currentMenu === "charts" ? "active" : ""} nav-link group w-full`}
                                    onClick={() => toggleMenu("charts")}
                                >
                                    <div className="flex items-center">
                                        <IconMenuCharts className="group-hover:!text-primary shrink-0" />
                                        <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                                            {t("charts")}
                                        </span>
                                    </div>

                                    <div className={currentMenu !== "charts" ? "rtl:rotate-90 -rotate-90" : ""}>
                                        <IconCaretDown />
                                    </div>
                                </button>

                                <AnimateHeight duration={300} height={currentMenu === "charts" ? "auto" : 0}>
                                    <ul className="sub-menu text-gray-500">
                                        <li>
                                            <NavLink to="/reports/user/general">{t("used_score")}</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/reports/user/performance">{t("performance")}</NavLink>
                                        </li>
                                    </ul>
                                </AnimateHeight>
                            </li>

                            {/* Settings User Menu */}
                            <h2 className="py-3 px-7 flex items-center uppercase font-extrabold bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08] -mx-4 mb-1">
                                <IconMinus className="w-4 h-5 flex-none hidden" />
                                <span>{t("user_and_pages")}</span>
                            </h2>
                            <li className="nav-item">
                                <NavLink to="/users/user-account-settings">
                                    <div className="flex items-center">
                                        <IconUser className="group-hover:!text-primary shrink-0" />
                                        <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                                            {t("account_settings")}
                                        </span>
                                    </div>
                                </NavLink>
                            </li>

                            {/* Support Menu */}
                            <h2 className="py-3 px-7 flex items-center uppercase font-extrabold bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08] -mx-4 mb-1">
                                <IconMinus className="w-4 h-5 flex-none hidden" />
                                <span>{t("help")}</span>
                            </h2>

                            <li className="nav-item">
                                <NavLink to="https://www.nefroatual.com.br/#faq" target="_blank">
                                    <div className="flex items-center">
                                        <IconMenuDocumentation className="group-hover:!text-primary shrink-0" />
                                        <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                                            {t("faq")}
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                        </ul>
                    </PerfectScrollbar>
                </div>
            </nav>
        </div>
    );
};

export default Sidebar;
