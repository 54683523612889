import { qtestService } from "../../services/qtest/qtest.service";
import { IQTests } from "../../types/qTests/qTestsTypes";

const useQTest = () => {
    const findAllQTest = async (idTest: string) => {
        try {
            const response = await qtestService.findOne(idTest);
            return response;
        } catch (e) {
            // console.log(e);
        }
    };

    const updateQTest = async (newData: Partial<IQTests>) => {
        try {
            const response = await qtestService.update(newData);
            return response;
        } catch (e) {
            // console.log(e);
        }
    };

    return { findAllQTest, updateQTest };
};

export default useQTest;
