import { useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import { IQuestionCreatePayload } from "../../types/questions/questionsType";

import { ICategory } from "../../types/categories/categoryTypes";
import { useQuestions } from "../../hooks";
import useCategory from "../../hooks/category/useCategory";
import { useNavigate } from "react-router-dom";
import IconNotesEdit from "../../components/Icon/IconNotesEdit";
import { AnswerCreateComp, QuestionCreateComp } from "./components/createQuestion";
import IconFolderPlus from "../../components/Icon/IconFolderPlus";
import IconListCheck from "../../components/Icon/IconListCheck";

const QuestionModal = () => {
    // hooks
    const { t } = useTranslation();
    const { createQuestion } = useQuestions();
    const { findAllCategory } = useCategory();
    const { data: categories } = findAllCategory();
    const navigate = useNavigate();

    // states
    const [category, setCategory] = useState([]);
    const [values, setValues] = useState<IQuestionCreatePayload>({
        name: "",
        description: "",
        category_id: 0,
        answers: [
            {
                description: "",
                tutor_explanation: "",
                correct: true
            },
            {
                description: "",
                tutor_explanation: "",
                correct: false
            },
            {
                description: "",
                tutor_explanation: "",
                correct: false
            },
            {
                description: "",
                tutor_explanation: "",
                correct: false
            }
        ]
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [activeTab4, setActiveTab4] = useState<any>(1);

    useEffect(() => {
        const convertCategory = () => {
            let data: Partial<[{ value?: number; label?: string }]> = [];

            categories.forEach((element: ICategory) =>
                data.push({
                    value: element.id,
                    label: element.name
                })
            );
            setCategory(data as any);
        };

        convertCategory();
    }, [categories]);

    // functions
    const showMessage = (msg = "", type = "success") => {
        const toast: any = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            customClass: { container: "toast" }
        });
        toast.fire({
            icon: type,
            title: msg,
            padding: "10px 20px"
        });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;

        setValues((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSelectChange = (selectedOption: any) => {
        setValues((prev) => ({ ...prev, category_id: selectedOption?.value }));
    };

    const handleSubmit = async () => {
        // verify
        const requiredFields = [
            values.name,
            values.category_id,
            values.description,
            ...values.answers.flatMap((answer) => [answer.description, answer.tutor_explanation])
        ];

        if (requiredFields.some((field) => !field)) {
            showMessage(t("all_fields_required"), "error");
            return;
        }

        setLoading(true);

        // add new register logic
        await createQuestion(values).finally(() => {
            showMessage(t("save_success"));
        });

        navigate("/admin/questions/questions");
    };

    return (
        <div>
            <div className="flex-wrap flex-col w-full ">
                <div className="pb-5">
                    <div className="flex text-center items-center">
                        <div className="shrink-0">
                            <IconNotesEdit />
                        </div>
                        <h3 className="text-lg font-semibold ltr:ml-3 rtl:mr-3">{t("create_question")}</h3>
                    </div>
                </div>
                <div className="p-5 flex">
                    <form className="w-full">
                        {/* START */}
                        <div className="panel">
                            <div className="mb-5">
                                <div className="inline-block w-full">
                                    <div className="relative z-[1]">
                                        <div
                                            className={`${
                                                activeTab4 === 1
                                                    ? "w-[10%]"
                                                    : activeTab4 === 2
                                                    ? "w-[30%]"
                                                    : activeTab4 === 3
                                                    ? "w-[50%]"
                                                    : activeTab4 === 4
                                                    ? "w-[70%]"
                                                    : activeTab4 === 5
                                                    ? "w-[90%]"
                                                    // : activeTab4 === 6
                                                    // ? "w-[90%]"
                                                    : ""
                                            }
                                        bg-primary w-[10%] h-1 absolute ltr:left-0 rtl:right-0 top-[30px] m-auto -z-[1] transition-[width]`}
                                        ></div>
                                        <ul className="mb-5 grid grid-cols-5">
                                            {/* Question */}
                                            <li className="mx-auto">
                                                <button
                                                    type="button"
                                                    className={`${
                                                        activeTab4 === 1 ? "!border-primary !bg-primary text-white" : ""
                                                    }
                                                border-[3px] border-[#f3f2ee] bg-white dark:bg-[#253b5c] dark:border-[#1b2e4b] flex justify-center items-center w-16 h-16 rounded-full`}
                                                    onClick={() => setActiveTab4(1)}
                                                >
                                                    <IconFolderPlus />
                                                </button>
                                                <span
                                                    className={`${
                                                        activeTab4 === 1 ? "text-primary " : ""
                                                    }text-center block mt-2 mobile:hidden`}
                                                >
                                                    {t("question")}
                                                </span>
                                            </li>

                                            {/* Answer 1 */}
                                            <li className="mx-auto">
                                                <button
                                                    type="button"
                                                    className={`${
                                                        activeTab4 === 2
                                                            ? "!border-green-800 !bg-green-800 text-white dark:bg-green-900"
                                                            : ""
                                                    }
                                                border-[3px] border-[#f3f2ee] bg-white dark:bg-[#253b5c] dark:border-[#1b2e4b] flex justify-center items-center w-16 h-16 rounded-full`}
                                                    onClick={() => setActiveTab4(2)}
                                                >
                                                    <IconListCheck />
                                                </button>
                                                <span
                                                    className={`${
                                                        activeTab4 === 2 ? "text-success " : ""
                                                    }text-center block mt-2 mobile:hidden`}
                                                >
                                                    {t("answer")} 1
                                                </span>
                                            </li>

                                            {/* Answer 2 */}
                                            <li className="mx-auto">
                                                <button
                                                    type="button"
                                                    className={`${
                                                        activeTab4 === 3 ? "!border-danger !bg-danger text-white" : ""
                                                    }
                                                border-[3px] border-[#f3f2ee] bg-white dark:bg-[#253b5c] dark:border-[#1b2e4b] flex justify-center items-center w-16 h-16 rounded-full`}
                                                    onClick={() => setActiveTab4(3)}
                                                >
                                                    <IconListCheck />
                                                </button>
                                                <span
                                                    className={`${
                                                        activeTab4 === 3 ? "text-danger " : ""
                                                    }text-center block mt-2 mobile:hidden`}
                                                >
                                                    {t("answer")} 2
                                                </span>
                                            </li>

                                            {/* Answer 3 */}
                                            <li className="mx-auto">
                                                <button
                                                    type="button"
                                                    className={`${
                                                        activeTab4 === 4 ? "!border-danger !bg-danger text-white" : ""
                                                    }
                                                border-[3px] border-[#f3f2ee] bg-white dark:bg-[#253b5c] dark:border-[#1b2e4b] flex justify-center items-center w-16 h-16 rounded-full`}
                                                    onClick={() => setActiveTab4(4)}
                                                >
                                                    <IconListCheck />
                                                </button>
                                                <span
                                                    className={`${
                                                        activeTab4 === 4 ? "text-danger " : ""
                                                    }text-center block mt-2 mobile:hidden`}
                                                >
                                                    {t("answer")} 3
                                                </span>
                                            </li>

                                            {/* Answer 4 */}
                                            <li className="mx-auto">
                                                <button
                                                    type="button"
                                                    className={`${
                                                        activeTab4 === 5 ? "!border-danger !bg-danger text-white" : ""
                                                    }
                                                border-[3px] border-[#f3f2ee] bg-white dark:bg-[#253b5c] dark:border-[#1b2e4b] flex justify-center items-center w-16 h-16 rounded-full`}
                                                    onClick={() => setActiveTab4(5)}
                                                >
                                                    <IconListCheck />
                                                </button>
                                                <span
                                                    className={`${
                                                        activeTab4 === 5 ? "text-danger " : ""
                                                    }text-center block mt-2 mobile:hidden`}
                                                >
                                                    {t("answer")} 4
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        {/* Content Question */}
                                        <p className="mb-5">
                                            {activeTab4 === 1 && (
                                                <QuestionCreateComp
                                                    category={category}
                                                    handleChange={handleChange}
                                                    handleSelectChange={handleSelectChange}
                                                    setValues={setValues}
                                                    values={values}
                                                />
                                            )}
                                        </p>

                                        {/* Content Answer 1 */}
                                        <p className="mb-5">
                                            {activeTab4 === 2 && (
                                                <AnswerCreateComp
                                                    setValues={setValues}
                                                    values={values}
                                                    arrAnswer={0} // index arr answer
                                                />
                                            )}
                                        </p>

                                        {/* Content Answer 2 */}
                                        <p className="mb-5">
                                            {activeTab4 === 3 && (
                                                <AnswerCreateComp
                                                    setValues={setValues}
                                                    values={values}
                                                    arrAnswer={1} // index arr answer
                                                />
                                            )}
                                        </p>

                                        {/* Content Answer 3 */}
                                        <p className="mb-5">
                                            {activeTab4 === 4 && (
                                                <AnswerCreateComp
                                                    setValues={setValues}
                                                    values={values}
                                                    arrAnswer={2} // index arr answer
                                                />
                                            )}
                                        </p>

                                        {/* Content Answer 4 */}
                                        <p className="mb-5">
                                            {activeTab4 === 5 && (
                                                <AnswerCreateComp
                                                    setValues={setValues}
                                                    values={values}
                                                    arrAnswer={3} // index arr answer
                                                />
                                            )}
                                        </p>

                                    </div>
                                    <div className="flex justify-between">
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger mr-2"
                                            disabled={loading}
                                            onClick={() => navigate(-1)}
                                        >
                                            Cancel
                                        </button>

                                        <div className="flex justify-end">
                                            <button
                                                type="button"
                                                className={`btn btn-warning ${activeTab4 === 1 ? "hidden" : ""} mr-2`}
                                                onClick={() => setActiveTab4(activeTab4 >= 1 ? activeTab4 - 1 : 1)}
                                            >
                                                Back
                                            </button>
                                            {activeTab4 === 5 ? (
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={handleSubmit}
                                                    disabled={loading}
                                                >
                                                    {loading && (
                                                        <span className="animate-ping w-3 h-3 ltr:mr-4 rtl:ml-4 inline-block rounded-full bg-white shrink-0"></span>
                                                    )}
                                                    {t("save")}
                                                </button>
                                            ) : (
                                                <button
                                                    type="button"
                                                    className="btn btn-primary ltr:ml-auto rtl:mr-auto"
                                                    onClick={() => setActiveTab4(activeTab4 <= 5 ? activeTab4 + 1 : 5)}
                                                >
                                                    Next
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* FINAL */}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default QuestionModal;
