import { lazy } from "react";
import TestExecution from "../pages/QTest/TestExecution";
import { CreateQuestionPage } from "../pages/AdminAccess";

const AnalyticsAdmin = lazy(() => import("../pages/AdminAccess/AnalyticsAdmin"));
const AnswersPage = lazy(() => import("../pages/AdminAccess/Answers"));
const QuestionsPage = lazy(() => import("../pages/AdminAccess/Questions"));
const CategoryPage = lazy(() => import("../pages/AdminAccess/Category"));
const UsersList = lazy(() => import("../pages/AdminAccess/UsersList"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const CreateTest = lazy(() => import("../pages/Apps/CreateTest"));
const PreviousTest = lazy(() => import("../pages/Apps/PreviousTest"));
const AccountSetting = lazy(() => import("../pages/Users/AccountSetting"));
const ERROR404 = lazy(() => import("../pages/Pages/Error404"));
const ERROR500 = lazy(() => import("../pages/Pages/Error500"));
const ERROR503 = lazy(() => import("../pages/Pages/Error503"));
const Maintenence = lazy(() => import("../pages/Pages/Maintenence"));
const LoginCover = lazy(() => import("../pages/Authentication/LoginCover"));
const ReportsUser = lazy(() => import("../pages/Reports/ReportsUser"));

// Wireframes
const WireFrame = lazy(() => import("../pages/Dashboard/Wireframe"));

const routes = [
    // dashboard
    {
        path: "/",
        element: <WireFrame />
    },

    // App route
    {
        path: "/apps/create_test",
        element: <CreateTest />
    },
    {
        path: "/apps/previous_tests",
        element: <PreviousTest />
    },

    // Admin routes
    {
        path: "/admin/users",
        element: <UsersList />
    },
    {
        path: "/admin/questions/category",
        element: <CategoryPage />
    },
    {
        path: "/admin/questions/questions",
        element: <QuestionsPage />
    },
    {
        path: "/admin/questions/create-questions",
        element: <CreateQuestionPage />
    },
    {
        path: "/admin/questions/answers",
        element: <AnswersPage />
    },
    {
        path: "/admin/analytics",
        element: <AnalyticsAdmin />
    }, // not implemented yet

    // Setting route (users)
    {
        path: "/users/user-account-settings",
        element: <AccountSetting />
    },

    // Reports page (users)
    {
        path: "/reports/user/general",
        element: <ReportsUser />
    },
    {
        path: "/reports/user/performance",
        element: <WireFrame />
    },

    // Pages error route
    {
        path: "/pages/error404",
        element: <ERROR404 />,
        layout: "blank"
    },
    {
        path: "/pages/error500",
        element: <ERROR500 />,
        layout: "blank"
    },
    {
        path: "/pages/error503",
        element: <ERROR503 />,
        layout: "blank"
    },
    {
        path: "/pages/maintenence",
        element: <Maintenence />,
        layout: "blank"
    },

    //Test Execution
    {
        path: "/apps/test/:idtest/:index",
        element: <TestExecution />
    },

    //Authentication
    {
        path: "/auth/login",
        element: <LoginCover />,
        layout: "blank"
    },
    {
        path: "*",
        element: <ERROR404 />,
        layout: "blank"
    }
];

export { routes };
