import { api } from "../../api";
import { RoutesWithoutParams, routesWithParams } from "../../hooks/useFetch/getRoutes";
import { IDataSubmitCreateTest, IDataSubmitCreateTestPayload } from "../../pages/Apps/types";

export const testService = {
    findOne: async (idTest: string) => {
        const response = await api.get(routesWithParams.findOneTest(idTest));
        return response.data.data;
    },

    create: async (data: IDataSubmitCreateTestPayload) => {
        const response = await api.post(RoutesWithoutParams.CreateTest, data);
        return response.data.data
    },

    update: async (iduser: string) => {
        return;
    }
};
