import SimpleMdeReact from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import { IQuestionCreatePayload } from "../../../../types/questions/questionsType";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import IconInfoTriangle from "../../../../components/Icon/IconInfoTriangle";

interface IProps {
    values: IQuestionCreatePayload;
    setValues: Dispatch<SetStateAction<IQuestionCreatePayload>>;
    arrAnswer: number;
}

const AnswerCreateComp = ({ values, setValues, arrAnswer = 0 }: IProps) => {
    // hooks
    const { t } = useTranslation();

    return (
        <>
            <div className="items-center w-full flex justify-center mb-5">
                <div
                    className={`relative flex items-center border p-3.5 rounded ${
                        !arrAnswer
                            ? "text-success bg-success-light border-success"
                            : "text-danger bg-danger-light border-danger"
                    } ltr:border-r-[64px] rtl:border-l-[64px] ${
                        !arrAnswer ? "dark:bg-green-800" : "dark:bg-red-700"
                    } dark:text-white-light dark:border-white-light/20 w-auto`}
                >
                    <span className="absolute ltr:-right-11 rtl:-left-11 inset-y-0 text-white w-6 h-6 m-auto">
                        <IconInfoTriangle />
                    </span>
                    <span className="ltr:pr-2 rtl:pl-2">
                        <strong className="ltr:mr-1 rtl:ml-1">{t("answer_creation_title")}</strong>:{" "}
                        {!arrAnswer ? t("answer_creation_description_1") : t("answer_creation_description_2")}
                    </span>
                    <button type="button" className="ltr:ml-auto rtl:mr-auto hover:opacity-80"></button>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mobile:grid-cols-none">
                <div className="mb-5 mobile:mb-1">
                    <label htmlFor="description">
                        {t("answer")} {arrAnswer + 1}
                    </label>
                    <SimpleMdeReact
                        value={values.answers[arrAnswer].description}
                        onChange={(value) =>
                            setValues((prev) => ({
                                ...prev,
                                answers: prev.answers?.map((answer, index) =>
                                    index === arrAnswer ? { ...answer, description: value } : answer
                                )
                            }))
                        }
                    />
                </div>
                <div className="mb-5">
                    <label htmlFor="description">
                        {t("tutor_explanation")} {arrAnswer + 1}
                    </label>
                    <SimpleMdeReact
                        value={values.answers[arrAnswer].tutor_explanation}
                        onChange={(value) =>
                            setValues((prev) => ({
                                ...prev,
                                answers: prev.answers.map((answer, index) =>
                                    index === arrAnswer ? { ...answer, tutor_explanation: value } : answer
                                )
                            }))
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default AnswerCreateComp;
