import { api } from "../../api";
import { RoutesWithoutParams, routesWithParams } from "../../hooks/useFetch/getRoutes";
import { IQTests } from "../../types/qTests/qTestsTypes";

export const qtestService = {
    findOne: async (idTest: string) => {
        const response = await api.get(routesWithParams.findAllQTestByTestId(idTest));
        return response.data.data;
    },

    update: async (newData: Partial<IQTests>) => {
        const response = await api.put(RoutesWithoutParams.UpdateQTest, newData);
        return response;
    }
};
