import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { IDataSubmit } from "../types";

const Countdown = ({
    minutes = 1,
    idTest,
    finalIndex,
    setDataSubmit
}: {
    minutes?: number;
    idTest: string;
    finalIndex: number;
    setDataSubmit: Dispatch<SetStateAction<IDataSubmit>>;
}) => {
    const navigate = useNavigate();

    const [time, setTime] = useState(minutes * 60);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(intervalId);
                    // navigate(`/apps/test/${idTest}/${finalIndex}`);
                    setDataSubmit((prev) => ({
                        ...prev,
                        saved: true
                    }));
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const formatTime = (time: number) => {
        const mins = Math.floor(time / 60);
        const secs = time % 60;
        return { mins, secs };
    };

    const { mins, secs } = formatTime(time);

    return (
        <div>
            <div className="">
                <div className="">
                    <div className="flex">
                        <div>
                            <div className="w-10 h-10 sm:w-[50px] sm:h-[50px] shadow-[1px_2px_12px_0_rgba(31,45,61,0.10)] rounded border border-white-light dark:border-[#1b2e4b] flex justify-center flex-col">
                                <h1 className="text-primary sm:text-3xl text-xl text-center">{mins}</h1>
                            </div>
                        </div>
                        <div>
                            <div className="w-10 h-10 sm:w-[50px] sm:h-[50px] shadow-[1px_2px_12px_0_rgba(31,45,61,0.10)] rounded border border-white-light dark:border-[#1b2e4b] flex justify-center flex-col">
                                <h1 className="text-primary sm:text-3xl text-xl text-center">
                                    {secs.toString().padStart(2, "0")}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Countdown;
