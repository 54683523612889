import { useSelector } from "react-redux";
import { IRootState } from "../../../store";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

interface IProps {
    ommited: number;
    correct: number;
    incorrect: number;
    score: number | string;
}

const ChartfinalScore = ({ correct, incorrect, ommited, score }: IProps) => {
    // hooks
    const { t } = useTranslation();
    const isDark = useSelector(
        (state: IRootState) => state.themeConfig.theme === "dark" || state.themeConfig.isDarkMode
    );

    let scoreChartOptions: any = {
        series: [correct, incorrect, ommited],
        options: {
            chart: {
                type: "donut",
                height: 460,
                fontFamily: "Nunito, sans-serif"
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 25,
                colors: isDark ? "#0e1726" : "#fff"
            },
            colors: isDark ? ["#5c1ac3", "#e2a03f", "#e7515a"] : ["#e2a03f", "#5c1ac3", "#e7515a"],
            legend: {
                position: "bottom",
                horizontalAlign: "center",
                fontSize: "14px",
                markers: {
                    width: 10,
                    height: 10,
                    offsetX: -2
                },
                height: 50,
                offsetY: 20
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: "65%",
                        background: "transparent",
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: "29px",
                                offsetY: -10
                            },
                            value: {
                                show: true,
                                fontSize: "26px",
                                color: isDark ? "#bfc9d4" : undefined,
                                offsetY: 16,
                                formatter: (val: any) => {
                                    return val;
                                }
                            },
                            total: {
                                show: true,
                                label: t("score"),
                                color: "#888ea8",
                                fontSize: "29px",
                                formatter: () => {
                                    return score + " %";
                                }
                            }
                        }
                    }
                }
            },
            labels: [t("correct"), t("incorrect"), t("ommited")],
            states: {
                hover: {
                    filter: {
                        type: "none",
                        value: 0.15
                    }
                },
                active: {
                    filter: {
                        type: "none",
                        value: 0.15
                    }
                }
            }
        }
    };

    return (
        <div>
            <div className="panel h-full">
                <div>
                    <div className="bg-white dark:bg-black rounded-lg overflow-hidden">
                        {score && (
                            <ReactApexChart
                                series={scoreChartOptions.series}
                                options={scoreChartOptions.options}
                                type="donut"
                                height={460}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChartfinalScore;
