import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setPageTitle, toggleRTL } from "../../store/themeConfigSlice";
import Dropdown from "../../components/Dropdown";
import { IRootState } from "../../store";
import i18next from "i18next";
import IconCaretDown from "../../components/Icon/IconCaretDown";
import IconMail from "../../components/Icon/IconMail";
import IconLockDots from "../../components/Icon/IconLockDots";

// items to update in next sprints
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth/useAuth";
import { IUserRequest } from "../../types/users/userTypes";
import Swal from "sweetalert2";

const LoginCover = () => {
    //hooks
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const themeConfig = useSelector((state: IRootState) => state.themeConfig);
    const { login } = useAuth();

    // states
    const [flag, setFlag] = useState(themeConfig.locale);
    const [loading, setLoading] = useState<boolean>(false);
    const [dataSubmit, setDataSubmit] = useState<IUserRequest>({
        email: "",
        password: ""
    });

    // functions
    useEffect(() => {
        dispatch(setPageTitle("Login"));
    });

    const setLocale = (flag: string) => {
        setFlag(flag);
        if (flag.toLowerCase() === "ae") {
            dispatch(toggleRTL("rtl"));
        } else {
            dispatch(toggleRTL("ltr"));
        }
    };

    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setDataSubmit((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const resetForm = () => {
        setDataSubmit({
            email: "",
            password: ""
        });
    };

    const showMessage = (msg = "", type = "success") => {
        const toast: any = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            customClass: { container: "toast" }
        });
        toast.fire({
            icon: type,
            title: msg,
            padding: "10px 20px"
        });
    };

    const submitForm = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        await login(dataSubmit)
            .then(() => showMessage(t("login_successfully")))
            .catch((e) => showMessage(t("login_error"), "error"))
            .finally(() => {
                resetForm();
                setLoading(false);
            });
    };

    // start render
    return (
        <div>
            <div className="absolute inset-0">
                <img src="/assets/images/auth/bg-gradient.png" alt="image" className="h-full w-full object-cover" />
            </div>
            <div className="relative flex min-h-screen items-center justify-center bg-[url(/assets/images/auth/map.png)] bg-cover bg-center bg-no-repeat px-6 py-10 dark:bg-[#060818] sm:px-16">
                <img
                    src="/assets/images/auth/coming-soon-object1.png"
                    alt="image"
                    className="absolute left-0 top-1/2 h-full max-h-[893px] -translate-y-1/2"
                />
                <img
                    src="/assets/images/auth/coming-soon-object2.png"
                    alt="image"
                    className="absolute left-24 top-0 h-40 md:left-[30%]"
                />
                <img
                    src="/assets/images/auth/coming-soon-object3.png"
                    alt="image"
                    className="absolute right-0 top-0 h-[300px]"
                />
                <img src="/assets/images/auth/polygon-object.svg" alt="image" className="absolute bottom-0 end-[28%]" />
                <div className="relative flex w-full max-w-[1502px] flex-col justify-between overflow-hidden rounded-md bg-white/60 backdrop-blur-lg dark:bg-black/50 lg:min-h-[758px] lg:flex-row lg:gap-10 xl:gap-0">
                    <div className="relative hidden w-full items-center justify-center bg-[linear-gradient(225deg,rgba(239,18,98,1)_0%,rgba(67,97,238,1)_100%)] p-5 lg:inline-flex lg:max-w-[835px] xl:-ms-28 ltr:xl:skew-x-[14deg] rtl:xl:skew-x-[-14deg]">
                        <div className="absolute inset-y-0 w-8 from-primary/10 via-transparent to-transparent ltr:-right-10 ltr:bg-gradient-to-r rtl:-left-10 rtl:bg-gradient-to-l xl:w-16 ltr:xl:-right-20 rtl:xl:-left-20"></div>
                        <div className="ltr:xl:-skew-x-[14deg] rtl:xl:skew-x-[14deg]">
                            <Link to="/" className="w-48 block lg:w-72 ms-10">
                                <img src="/assets/images/auth/logoNefro.png" alt="Logo" className="w-full" />
                            </Link>
                            <div className="mt-24 hidden w-full max-w-[430px] lg:block">
                                <img src="/assets/images/auth/login.png" alt="Cover Image" className="w-full" />
                            </div>
                        </div>
                    </div>
                    <div className="relative flex w-full flex-col items-center justify-center gap-6 px-4 pb-16 pt-6 sm:px-6 lg:max-w-[667px]">
                        <div className="flex w-full max-w-[440px] items-center gap-2 lg:absolute lg:end-6 lg:top-6 lg:max-w-full">
                            <div className="dropdown ms-auto w-max">
                                <Dropdown
                                    offset={[0, 8]}
                                    placement={"bottom-end"}
                                    btnClassName="flex items-center gap-2.5 rounded-lg border border-white-dark/30 bg-white px-2 py-1.5 text-white-dark hover:border-primary hover:text-primary dark:bg-black"
                                    button={
                                        <>
                                            <div>
                                                <img
                                                    src={`/assets/images/flags/${flag.toUpperCase()}.svg`}
                                                    alt="image"
                                                    className="h-5 w-5 rounded-full object-cover"
                                                />
                                            </div>
                                            <div className="text-base font-bold uppercase">{flag}</div>
                                            <span className="shrink-0">
                                                <IconCaretDown />
                                            </span>
                                        </>
                                    }
                                >
                                    <ul className="!px-2 text-dark dark:text-white-dark grid grid-cols-2 gap-2 font-semibold dark:text-white-light/90 w-[280px]">
                                        {themeConfig.languageList.map((item: any) => {
                                            return (
                                                <li key={item.code}>
                                                    <button
                                                        type="button"
                                                        className={`flex w-full hover:text-primary rounded-lg ${
                                                            flag === item.code ? "bg-primary/10 text-primary" : ""
                                                        }`}
                                                        onClick={() => {
                                                            i18next.changeLanguage(item.code);
                                                            // setFlag(item.code)
                                                            setLocale(item.code);
                                                        }}
                                                    >
                                                        <img
                                                            src={`/assets/images/flags/${item.code.toUpperCase()}.svg`}
                                                            alt="flag"
                                                            className="w-5 h-5 object-cover rounded-full"
                                                        />
                                                        <span className="ltr:ml-3 rtl:mr-3">{item.name}</span>
                                                    </button>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="w-full max-w-[440px] lg:mt-16">
                            <div className="mb-10">
                                <h1 className="text-3xl font-extrabold uppercase !leading-snug text-primary md:text-4xl">
                                    {t("sign_in")}
                                </h1>
                                <p className="text-base font-bold leading-normal text-white-dark">
                                    {t("enter_credentials")}
                                </p>
                            </div>

                            {/* Form block */}
                            <form className="space-y-5 dark:text-white" onSubmit={submitForm}>
                                <div>
                                    <label htmlFor="Email">{t("email")}</label>
                                    <div className="relative text-white-dark">
                                        <input
                                            id="email"
                                            type="email"
                                            name="email"
                                            value={dataSubmit.email}
                                            required={true}
                                            placeholder={t("enter_email")}
                                            className="form-input ps-10 placeholder:text-white-dark"
                                            onChange={handleInput}
                                        />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconMail fill={true} />
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="Password">{t("password")}</label>
                                    <div className="relative text-white-dark">
                                        <input
                                            id="password"
                                            type="password"
                                            name="password"
                                            value={dataSubmit.password}
                                            required={true}
                                            placeholder={t("enter_password")}
                                            className="form-input ps-10 placeholder:text-white-dark"
                                            onChange={handleInput}
                                        />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconLockDots fill={true} />
                                        </span>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="btn btn-primary !mt-6 w-full border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]"
                                >
                                    {loading && (
                                        <span className="animate-ping w-3 h-3 ltr:mr-4 rtl:ml-4 inline-block rounded-full bg-white shrink-0"></span>
                                    )}
                                    {t("sign_in")}
                                </button>
                            </form>

                            <div className="text-center dark:text-white mt-2">
                                {t("without_account")}{" "}
                                <Link
                                    to="https://www.nefroatual.com.br/register"
                                    target="_blank"
                                    className="uppercase text-primary underline transition hover:text-black dark:hover:text-white"
                                >
                                    {t("sign_up")}
                                </Link>
                            </div>
                            <div className="text-center dark:text-white mt-2">
                                {" "}
                                <Link
                                    to="https://www.nefroatual.com.br/forgotPassword"
                                    target="_blank"
                                    className=" text-primary underline transition hover:text-black dark:hover:text-white"
                                >
                                    {t("forgot_password")}
                                </Link>
                            </div>
                        </div>
                        <p className="absolute bottom-6 w-full text-center dark:text-white">
                            © {new Date().getFullYear()}. {t("name_app")} - {t("right_reserved")}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginCover;
