import { combineReducers, configureStore } from "@reduxjs/toolkit";
import themeConfigSlice from "./themeConfigSlice";
import userSlice from "./userSlice";
// import tasksSlice from "./model_thunks/tasksSlice";
import sessionSlice from "./sessionSlice";
import globalMsgSlice from "./globalMsgSlice";

const rootReducer = combineReducers({
    themeConfig: themeConfigSlice,
    userConfig: userSlice,
    sessionConfig: sessionSlice,
    globalMsgConfig: globalMsgSlice
});

export default configureStore({
    reducer: rootReducer
});

export type IRootState = ReturnType<typeof rootReducer>;
