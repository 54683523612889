import IconChecks from "../../../components/Icon/IconChecks";
import IconInfoTriangle from "../../../components/Icon/IconInfoTriangle";
import IconX from "../../../components/Icon/IconX";
import { IQTests } from "../../../types/qTests/qTestsTypes";

interface IProps {
    element: IQTests;
    index: number;
    number: number;
    handleSubmit: (value: string, key: number) => void;
    tutored?: boolean;
}

const RenderListQuestions = ({ element, index, number, handleSubmit, tutored }: IProps) => {
    return (
        <div>
            <div
                className={`${
                    index === number + 1 && "bg-black-light rounded text-black"
                } p-1 cursor-pointer flex items-center justify-around`}
                onClick={() => handleSubmit("key", number)}
            >
                {number + 1}
                {element.correct === true ? (
                    <IconChecks className="text-green-800" />
                ) : (
                    <>
                        {element.id_answer === 0 ? (
                            <IconInfoTriangle className="text-yellow-600" />
                        ) : (
                            <>
                                {tutored ? (
                                    <IconX className="text-red-500" />
                                ) : (
                                    <IconChecks className="text-green-800" />
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default RenderListQuestions;
