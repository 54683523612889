import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: "",
    name: "",
    email: "",
    phone: "",
    avatar: "",
    role: undefined as undefined | number
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        userData(state, { payload }) {
            state.id = payload.id ?? state.id;
            state.name = payload.name ?? state.name;
            state.email = payload.email ?? state.email;
            state.phone = payload.phone ?? state.phone;
            state.avatar = payload.avatar ?? state.avatar;
            state.role = payload.role ?? state.role;
        },
        validationSession(state, { payload }) {
            state.role = payload; // true or false
        },
        signOut(state) {
            state.id = "";
            state.name = "";
            state.email = "";
            state.phone = "";
            state.avatar = "";
            state.role = undefined as undefined | number;
        }
    }
});

export const { userData, validationSession, signOut } = userSlice.actions;

export default userSlice.reducer;
