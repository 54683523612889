import { createBrowserRouter, RouterProvider } from "react-router-dom";
import BlankLayout from "../components/Layouts/BlankLayout";
import DefaultLayout from "../components/Layouts/DefaultLayout";
import { routes } from "./routes";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../store";
import { userData } from "../store/userSlice";
import LoginCover from "../pages/Authentication/LoginCover";
import Swal from "sweetalert2";
import { useCallback, useEffect } from "react";
import useSession from "../hooks/useAuth/useSession";
import { session } from "../store/sessionSlice";

const RouterComponent = () => {
    // hooks
    const dispatch = useDispatch();
    const { data: validationSession, loading } = useSession(); // validate to refresh and get user's data

    // subscribe to change global status
    const sessionData = useSelector((state: IRootState) => state.sessionConfig);
    const globalMessages = useSelector((state: IRootState) => state.globalMsgConfig);

    // toast message based in session
    const showMessage = useCallback(() => {
        if (sessionData.code !== 0 && sessionData.code !== 401) {
            const toast: any = Swal.mixin({
                toast: true,
                position: "top",
                showConfirmButton: false,
                timer: 3000,
                customClass: { container: "toast" }
            });
            toast.fire({
                icon: sessionData.code === 200 ? "success" : "error",
                title: sessionData.message,
                padding: "10px 20px"
            });
        }
    }, [sessionData]);

    // global messages
    const showGlobalMessages = useCallback(() => {
        if (globalMessages.code !== 0) {
            const toast: any = Swal.mixin({
                toast: true,
                position: "top",
                showConfirmButton: false,
                timer: 3000,
                customClass: { container: "toast" }
            });
            toast.fire({
                icon: globalMessages.code === 200 ? "success" : "error",
                title: globalMessages.message,
                padding: "10px 20px"
            });
        }
    }, [globalMessages]);

    useEffect(() => {
        if (validationSession.id) {
            dispatch(userData(validationSession));
            dispatch(
                session({
                    valid: true,
                    code: 200
                    // message: "Session validated"
                })
            );
        }
    }, [validationSession]);

    // showMessage();
    // showGlobalMessages();

    if (loading) {
        return <></>;
    }

    const finalRoutes =
        sessionData.code === 200
            ? routes.map((route) => ({
                  ...route,
                  element:
                      route.layout === "blank" ? (
                          <BlankLayout>{route.element}</BlankLayout>
                      ) : (
                          <DefaultLayout>{route.element}</DefaultLayout>
                      )
              }))
            : routes.map((route) => ({
                  ...route,
                  element: (
                      <BlankLayout>
                          <LoginCover />
                      </BlankLayout>
                  )
              }));

    const router = createBrowserRouter(finalRoutes as any);

    return <RouterProvider router={router} />;
};

export default RouterComponent;
