const Portals = () => {
    return (
        <>
            <div id="popper-portal"></div>
            <div id="modal-portal"></div>
        </>
    );
};

export default Portals;
