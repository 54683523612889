import Select from "react-select";
import SimpleMdeReact from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import { IQuestionCreatePayload } from "../../../../types/questions/questionsType";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { ICategory } from "../../../../types/categories/categoryTypes";
import { useTranslation } from "react-i18next";
import IconInfoTriangle from "../../../../components/Icon/IconInfoTriangle";

interface IProps {
    values: IQuestionCreatePayload;
    handleChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    category: ICategory[];
    handleSelectChange: (e: any) => void;
    setValues: Dispatch<SetStateAction<IQuestionCreatePayload>>;
}

const QuestionCreateComp = ({ values, handleChange, category, handleSelectChange, setValues }: IProps) => {
    // hooks
    const { t } = useTranslation();

    return (
        <>
            <div className="items-center w-full flex justify-center mb-5">
                <div className="relative flex items-center border p-3.5 rounded text-dark bg-dark-light border-dark ltr:border-r-[64px] rtl:border-l-[64px] dark:bg-dark-dark-light dark:text-white-light dark:border-white-light/20 w-auto">
                    <span className="absolute ltr:-right-11 rtl:-left-11 inset-y-0 text-white w-6 h-6 m-auto">
                        <IconInfoTriangle />
                    </span>
                    <span className="ltr:pr-2 rtl:pl-2">
                        <strong className="ltr:mr-1 rtl:ml-1">{t("question_creation_title")}</strong>: {t("question_creation_description")}
                    </span>
                    <button type="button" className="ltr:ml-auto rtl:mr-auto hover:opacity-80"></button>
                </div>
            </div>
            <div className="flex w-full mobile:flex-col">
                <div className="w-1/3 mobile:w-full">
                    <div className="mb-5">
                        <label htmlFor="name">{t("name")}</label>
                        <input
                            name="name"
                            type="text"
                            placeholder="Enter Name"
                            className="form-input !bg-white !text-black font-light"
                            required={true}
                            value={values.name}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="mb-5">
                        <label htmlFor="category">{t("category")}</label>
                        <Select
                            className="text-black"
                            name="category_id"
                            placeholder={t("select_an_option")}
                            options={category}
                            onChange={handleSelectChange}
                        />
                    </div>
                </div>
                <div className="mb-5 w-full ml-2 mobile:ml-0">
                    <label htmlFor="description">{t("description")}</label>
                    <SimpleMdeReact
                        value={values.description}
                        onChange={(value) =>
                            setValues((prev) => ({
                                ...prev,
                                description: value
                            }))
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default QuestionCreateComp;
