import { useSelector } from "react-redux";
import { questionService } from "../../services/question/question.service";
import { IRootState } from "../../store";
import { useFetch } from "../useFetch";
import { IQuestion, IQuestionCreatePayload, IQuestionPayload } from "../../types/questions/questionsType";
import { RoutesWithoutParams } from "../useFetch/getRoutes";
import { IErrorResponse } from "../../types/serverResponse/types";
import { useTranslation } from "react-i18next";

const useQuestions = () => {
    const iduser = useSelector((state: IRootState) => state.userConfig.id);
    const { t } = useTranslation();

    const findAllQuestion = () =>
        useFetch<IQuestion[]>(RoutesWithoutParams.FindAllQuestions, {
            initialValue: []
        });

    const findOneQuestion = async (idQuestion: number) => {
        try {
            const response = await questionService.findOne(idQuestion);
            return response;
        } catch (e) {
            // console.log(e);
        }
    };

    const findAllQuestionByCategory = async (category: number) => {
        try {
            const response = await questionService.findAllByCategory(category);
            return response;
        } catch (e) {
            // console.log(e);
        }
    };

    const allUnusedQuestions = async (category: number) => {
        try {
            const response = await questionService.findAllUnused(iduser, category);
            return response;
        } catch (e) {
            // console.log(e);
        }
    };

    const allUnusedQuestionsMultipleCategories = async (category: number[]) => {
        try {
            const response = await questionService.findAllUnusedByMultipleCategory(iduser, category);
            return response;
        } catch (e) {
            // console.log(e);
        }
    };

    const createQuestion = async (payload: IQuestionCreatePayload) => {
        try {
            const response = await questionService.create(payload);
            return response;
        } catch (e: unknown) {
            const error = e as IErrorResponse;
            throw new Error(error.response.data.code);
        }
    };

    const updateQuestion = async (idQuestion: number, payload: IQuestionPayload) => {
        try {
            const response = await questionService.update(idQuestion, payload);
            return response;
        } catch (e) {
            // console.log(e);
        }
    };

    const deleteQuestion = async (idQuestion: number) => {
        try {
            const response = await questionService.destroy(idQuestion);
            return response;
        } catch (e: unknown) {
            const error = e as IErrorResponse;
            throw new Error(
                `${error.response.data.code === "D001" ? t("error_D001_delete_question") : error.response.data.code}`
            );
        }
    };

    return {
        findOneQuestion,
        findAllQuestion,
        allUnusedQuestions,
        findAllQuestionByCategory,
        createQuestion,
        updateQuestion,
        deleteQuestion,
        allUnusedQuestionsMultipleCategories
    };
};

export default useQuestions;
