import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    valid: false,
    code: 0,
    message: ""
};

const globalMsgSlice = createSlice({
    name: "globalMessages",
    initialState,
    reducers: {
        globalMessage(state, { payload }) {
            state.valid = payload.valid;
            state.code = payload.code;
            state.message = payload.message;
        }
    }
});

export const { globalMessage } = globalMsgSlice.actions;

export default globalMsgSlice.reducer;
