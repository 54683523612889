import { Fragment, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { setPageTitle } from "../../store/themeConfigSlice";

import { useTranslation } from "react-i18next";
import IconNotesEdit from "../../components/Icon/IconNotesEdit";
import { useNavigate, useParams } from "react-router-dom";
import { IQTests } from "../../types/qTests/qTestsTypes";
import { ITest } from "../../types/test_created/testTypes";
import IconArrowForward from "../../components/Icon/IconArrowForward";
import IconArrowBackward from "../../components/Icon/IconArrowBackward";
import { Countdown, FinishedTest, QuestionTestComp, QuestionTestWithouTutor, RenderListQuestions } from "./components";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import IconWheel from "../../components/Icon/IconWheel";
import useQTest from "../../hooks/qTest/useQTest";
import useQuestions from "../../hooks/questions/useQuestions";
import useTest from "../../hooks/testsHook/useTest";
import { IQuestion } from "../../types/questions/questionsType";
import { IDataSubmit } from "./types";
import { IRootState } from "../../store";
import useAuth from "../../hooks/useAuth/useAuth";
import IconCoffee from "../../components/Icon/IconCoffee";

const TestExecution = () => {
    const iduser = useSelector((state: IRootState) => state.userConfig.id);
    const { logout } = useAuth();

    // hooks
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { idtest, index } = useParams();
    const navigate = useNavigate();

    const { findOneTest } = useTest();
    const { findAllQTest, updateQTest } = useQTest();
    const { findOneQuestion } = useQuestions();

    // states
    const [loading, setLoading] = useState<boolean>(false); // state to saving data
    const [loadingData, setLoadingData] = useState({
        loadingTest: true,
        loadingQTest: true,
        loadingQuestion: true
    }); // state loading data

    const [testData, setTestData] = useState<Partial<ITest>>({
        tutor: false,
        timed: false
    });
    const [qTestData, setQTestData] = useState<IQTests[]>([]);
    const [questionData, setQuestionData] = useState<Partial<IQuestion>>({});
    const [dataSubmit, setDataSubmit] = useState<IDataSubmit>({
        id_question: 0,
        id_answer: 0,
        id_test: idtest,
        correct: false,
        id_user: iduser,
        saved: false
    });
    const [fontSize, setFontSize] = useState<number>(0);
    const [size, setSize] = useState<string[]>(["base", "lg", "xl"]);

    // functions
    useEffect(() => {
        dispatch(setPageTitle("test"));

        async function getData() {
            await findOneTest(idtest as string)
                .then((response) => {
                    setLoadingData((prev) => ({
                        ...prev,
                        loadingTest: false
                    }));
                    setTestData(response);
                })
                .catch(logout);
        }
        getData();
    }, []);

    useEffect(() => {
        async function getData() {
            await findAllQTest(idtest as string).then((response) => {
                setLoadingData((prev) => ({
                    ...prev,
                    loadingQTest: false
                }));
                setQTestData(response);
            });
        }
        getData();
    }, [index]);

    useEffect(() => {
        async function getData() {
            if (qTestData.length > 0 && Number(index) < qTestData.length + 1) {
                await findOneQuestion(qTestData[Number(index) - 1].id_question).then((response) => {
                    setLoadingData((prev) => ({
                        ...prev,
                        loadingQuestion: false
                    }));
                    setQuestionData(response);
                });

                // set initial values to data that will be submited and saved
                setDataSubmit({
                    id_question: qTestData[Number(index) - 1].id_question,
                    id_answer: qTestData[Number(index) - 1].id_answer,
                    id_test: idtest,
                    correct: qTestData[Number(index) - 1].correct,
                    id_user: iduser,
                    saved: qTestData[Number(index) - 1].id_answer ? true : false
                });
                setLoading(false);
            }
        }
        getData();
    }, [qTestData]);

    // console.log(dataSubmit);

    const handleSubmit = async (value: string, key = 0) => {
        setLoading(true);

        // normalize
        delete dataSubmit.saved;

        await updateQTest(dataSubmit)
            .then(() => console.log("Saved..."))
            .catch(() => {});

        switch (value) {
            case "next":
                navigate(`/apps/test/${idtest}/${Number(index) + 1}`);
                break;
            case "previous":
                navigate(`/apps/test/${idtest}/${Number(index) - 1}`);
                break;
            case "key":
                navigate(`/apps/test/${idtest}/${key + 1}`);
                break;
            default:
                break;
        }
    };

    if (loadingData.loadingQuestion) {
        return (
            <>
                <div className="flex items-center w-full h-96 justify-center">
                    <div className="grid grid-cols-1 w-full gap-4">
                        <span className="w-8 h-8 m-auto mb-10">
                            <span className="animate-ping inline-flex h-full w-full rounded-full bg-info"></span>
                        </span>
                    </div>
                </div>
            </>
        );
    }

    if (Number(index) > qTestData.length && Number(index) !== 1) {
        return <FinishedTest qTestData={qTestData} />;
    }

    // render
    return (
        <div>
            <div className="flex-1 items-center justify-between flex-wrap gap-4">
                <div className="flex-col">
                    <div className="pb-5">
                        <div className="flex text-center items-center w-full justify-around">
                            {/* <div className="flex items-center"> */}
                            {/* <div className="shrink-0">
                                    <IconNotesEdit />
                                </div>
                                <h3 className="text-lg font-semibold ltr:ml-3 rtl:mr-3">{idtest}</h3> */}
                            {/* </div> */}
                            <div className="flex w-auto justify-between items-center mobile:flex-col">
                                {testData.tutor && (
                                    <div className="mr-2 mobile:mb-2">
                                        <Tippy content="Tutor On" theme="primary">
                                            <button type="button" className="btn btn-primary">
                                                <IconWheel className="mr-2" />
                                                Tutor
                                            </button>
                                        </Tippy>
                                    </div>
                                )}
                                {testData.timed && (
                                    <div className="mr-3 mobile:mb-2">
                                        {!loading && (
                                            <Countdown
                                                minutes={1}
                                                setDataSubmit={setDataSubmit}
                                                idTest={idtest as string}
                                                finalIndex={qTestData.length + 1}
                                            />
                                        )}
                                    </div>
                                )}
                                <div>
                                    <div className="border p-1 rounded mobile:flex">
                                        <button
                                            className="bg-white p-1 text-black text-[18px] mr-1 rounded-sm"
                                            onClick={() => setFontSize(fontSize >= 2 ? 2 : fontSize + 1)}
                                        >
                                            A
                                        </button>
                                        <button
                                            className="p-1 text-white text-[13px] bg-black rounded-sm dark:bg-none"
                                            onClick={() => setFontSize(fontSize <= 0 ? 0 : fontSize - 1)}
                                        >
                                            A
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="w-1/12 dark:bg-gray-900 bg-white rounded h-auto p-2 mobile:w-2/12">
                            {qTestData.map((element: IQTests, key: number) => {
                                return (
                                    <Fragment key={key}>
                                        <RenderListQuestions
                                            element={element}
                                            handleSubmit={handleSubmit}
                                            index={Number(index)}
                                            number={key}
                                            tutored={testData.tutor}
                                        />
                                    </Fragment>
                                );
                            })}
                        </div>
                        <div className="w-11/12 h-auto p-5">
                            {!loading && questionData.id ? (
                                <>
                                    {testData.tutor ? (
                                        <QuestionTestComp
                                            index={Number(index)}
                                            size={size[fontSize]}
                                            dataSubmit={dataSubmit}
                                            testData={testData}
                                            setDataSubmit={setDataSubmit}
                                            questionData={questionData}
                                            qTestData={qTestData[Number(index) - 1]}
                                        />
                                    ) : (
                                        <QuestionTestWithouTutor
                                            index={Number(index)}
                                            size={size[fontSize]}
                                            dataSubmit={dataSubmit}
                                            testData={testData}
                                            setDataSubmit={setDataSubmit}
                                            questionData={questionData}
                                            qTestData={qTestData[Number(index) - 1]}
                                        />
                                    )}
                                </>
                            ) : (
                                <div role="status" className="max-w-sm animate-pulse">
                                    <div className="h-6 bg-gray-400 rounded-full dark:bg-gray-700 max-w-[200px] mb-4"></div>
                                    <div className="h-5 bg-gray-400 rounded-full dark:bg-gray-700 max-w-[500px] mb-2.5"></div>
                                    <div className="h-5 bg-gray-400 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                                    <div className="h-5 bg-gray-400 rounded-full dark:bg-gray-700 mb-2.5"></div>
                                    <div className="h-6 bg-none rounded-full dark:bg-none max-w-[300px] mb-2.5"></div>
                                    <div className="h-2 bg-gray-400 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                                    <div className="h-2 bg-gray-400 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                                    <div className="h-2 bg-gray-400 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                                    <div className="h-2 bg-gray-400 rounded-full dark:bg-gray-700 max-w-[300px]"></div>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            )}
                            {!loading && (
                                <div className="flex items-center">
                                    {/* <button type="submit" className="btn btn-primary !mt-6"> */}
                                    {Number(index) > 1 && (
                                        <button
                                            onClick={() => handleSubmit("previous")}
                                            className="btn btn-warning !mt-6 mr-3"
                                        >
                                            <IconArrowBackward className="ml-2" />
                                            {t("back")}
                                        </button>
                                    )}

                                    {testData.tutor && (
                                        <button
                                            disabled={dataSubmit.id_answer && !dataSubmit.saved ? false : true}
                                            className="btn btn-secondary !mt-6 mr-2"
                                            onClick={() => setDataSubmit({ ...dataSubmit, saved: true })}
                                        >
                                            {dataSubmit.saved ? t("saved") : t("save")}
                                            <IconCoffee className="ml-2" />
                                        </button>
                                    )}

                                    <button onClick={() => handleSubmit("next")} className="btn btn-primary !mt-6">
                                        {Number(index) <= qTestData.length - 1 ? (
                                            <>
                                                {dataSubmit.id_answer === 0 ? (
                                                    <>{t("skip_question")}</>
                                                ) : (
                                                    <>{t("next_question")}</>
                                                )}{" "}
                                            </>
                                        ) : (
                                            <>{t("finish")}</>
                                        )}
                                        <IconArrowForward className="ml-2" />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestExecution;
