import { ChangeEvent, Fragment, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";

import { useTranslation } from "react-i18next";
import { IAnswer } from "../../../types/answers/answerType";
import { ITest } from "../../../types/test_created/testTypes";
import { IQTests } from "../../../types/qTests/qTestsTypes";
import { IQuestion } from "../../../types/questions/questionsType";
import { IDataSubmit } from "../types";
import { LoadingData } from "../../Components";

import Markdown from "react-markdown";

interface IProps {
    index: number;
    dataSubmit: IDataSubmit;
    setDataSubmit: (data: IDataSubmit) => void;
    // setDataSubmit: React.Dispatch<React.SetStateAction<IDataSubmit>>;
    questionData: Partial<IQuestion>;
    testData: Partial<ITest>;
    qTestData: IQTests;
    size: string;
}

const QuestionTestComp = ({ index, dataSubmit, setDataSubmit, questionData, testData, qTestData, size }: IProps) => {
    // hooks
    const { t } = useTranslation();

    // states
    const [answers, setAnswers] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    // functions
    useEffect(() => {
        if (answers.length === 0) {
            const result = questionData.answers?.sort(() => Math.random() - 0.5);
            setAnswers(result as []);
        }
    }, [index, dataSubmit, setDataSubmit, questionData, testData]);

    useEffect(() => {
        setLoading(false);
    }, [answers]);

    const handleAnswer = (e: ChangeEvent<HTMLInputElement>, correct: boolean) => {
        const { name, value } = e.target;

        setDataSubmit({
            ...dataSubmit,
            id_question: questionData.id,
            correct: correct,
            [name]: parseInt(value)
        });
    };

    if (loading) return <LoadingData />;

    function renderAnswers(element: IAnswer, key: number) {
        return (
            <Fragment key={key}>
                <div className={`${(qTestData.id_answer || dataSubmit.saved) && "pointer-events-none"}`}>
                    <label className="flex mt-9 cursor-pointer">
                        <input
                            type="radio"
                            name="id_answer"
                            className={`${
                                dataSubmit.saved
                                    ? element.correct
                                        ? "form-radio text-success"
                                        : "form-radio text-danger"
                                    : "form-radio"
                            } peer`}
                            value={element.id}
                            defaultChecked={element.id === qTestData.id_answer ? true : false}
                            onChange={(e) => handleAnswer(e, element.correct)}
                        />
                        <span
                            className={`${
                                dataSubmit.saved
                                    ? element.correct
                                        ? "text-success"
                                        : "text-danger"
                                    : "none"
                            }`}
                        >
                            <Markdown className={"whitespace-pre-wrap"}>{element.description}</Markdown>
                        </span>
                    </label>
                </div>

                <div>
                    {dataSubmit.saved && (
                        <Markdown className={"whitespace-pre-wrap"}>{element.tutor_explanation}</Markdown>
                    )}
                </div>
            </Fragment>
        );
    }

    // render
    return (
        <div>
            <div className="flex-1 items-center justify-between flex-wrap gap-4">
                <div className="flex-col">
                    <div className="flex">
                        <div className="w-fullh-auto p-5 h-auto">
                            <div className="text-2xl font-semibold mb-3">{t("question")}:</div>
                            <div className={`text-${size}`}>
                                <Markdown className={"whitespace-pre-wrap border-2 border-dark rounded-md p-2"}>
                                    {questionData.description}
                                </Markdown>
                            </div>
                            <div className={`text-${size}`}>
                                {answers.length > 0 && <div className="mt-10">{answers.map(renderAnswers)}</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuestionTestComp;
