import { useNavigate } from "react-router-dom";
import IconInfoCircle from "../../../components/Icon/IconInfoCircle";
import IconArchive from "../../../components/Icon/IconArchive";
import { useTranslation } from "react-i18next";
import { IQTests } from "../../../types/qTests/qTestsTypes";
import ChartfinalScore from "./ChartFinalScore";
import { useEffect, useState } from "react";

interface IProps {
    qTestData: IQTests[];
}

const FinishedTest = ({ qTestData }: IProps) => {
    // hooks
    const navigate = useNavigate();
    const { t } = useTranslation();

    // state
    const [score, setScore] = useState({
        ommited: 0,
        correct: 0,
        incorrect: 0,
        score: 0
    });

    useEffect(() => {
        const ommited = qTestData.filter((element: IQTests) => !element.id_answer).length;
        const correct = qTestData.filter((element: IQTests) => element.correct).length;
        const incorrect = qTestData.filter((element: IQTests) => !element.correct && element.id_answer).length;
        const score = Math.floor((correct / qTestData.length) * 100);

        function defineScore() {
            setScore({
                ommited: ommited,
                correct: correct,
                incorrect: incorrect,
                score: score
            });
        }

        defineScore();
    }, [qTestData]);

    return (
        <>
            <div className="flex w-full h-auto items-center justify-center">
                <div>
                    <div className="flex flex-wrap w-full justify-center mb-5">
                        <div className="border border-gray-500/20 rounded-md shadow-[rgb(31_45_61_/_10%)_0px_2px_10px_1px] dark:shadow-[0_2px_11px_0_rgb(6_8_24_/_39%)] p-6 pt-12 mt-8 relative">
                            <div className="bg-primary absolute text-white-light ltr:left-6 rtl:right-6 -top-8 w-16 h-16 rounded-md flex items-center justify-center mb-5 mx-auto">
                                <IconInfoCircle className="size-10" />
                            </div>
                            <h5 className="text-dark text-[25px] font-semibold mb-3.5 dark:text-white-light">
                                {t("congratulations")}
                            </h5>
                            <p className="text-white-dark text-[18px]  mb-3.5">{t("message_test_finished")}</p>
                            {score.score > 0 ? (
                                <>
                                    <ChartfinalScore
                                        ommited={score.ommited}
                                        correct={score.correct}
                                        incorrect={score.incorrect}
                                        score={score.score}
                                    />
                                </>
                            ) : (
                                <>
                                    <div className="relative flex items-center border p-3.5 rounded before:inline-block before:absolute before:top-1/2 ltr:before:right-0 rtl:before:left-0 rtl:before:rotate-180 before:-mt-2 before:border-r-8 before:border-t-8 before:border-b-8 before:border-t-transparent before:border-b-transparent before:border-r-inherit text-danger bg-danger-light border-danger ltr:border-r-[64px] rtl:border-l-[64px] dark:bg-danger-dark-light">
                                        <span className="absolute ltr:-right-11 rtl:-left-11 inset-y-0 text-white w-6 h-6 m-auto">
                                            <IconInfoCircle />
                                        </span>
                                        <span className="ltr:pr-2 rtl:pl-2">{t("without_score")}</span>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="items-center flex justify-center">
                        <button onClick={() => navigate(`/apps/previous_tests`)} className="btn btn-primary !mt-6">
                            <IconArchive className="mr-2" />
                            {t("return")}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FinishedTest;
