import { api } from "../../api";
import { RoutesWithoutParams, routesWithParams } from "../../hooks/useFetch/getRoutes";
import { IQuestionCreatePayload, IQuestionPayload } from "../../types/questions/questionsType";

export const questionService = {
    findAll: async () => {
        const response = await api.get(RoutesWithoutParams.FindAllQuestions);
        return response.data.data;
    },

    findOne: async (idQuestion: number) => {
        const response = await api.get(routesWithParams.findOneQuestion(idQuestion));
        return response.data.data;
    },
    findAllUnused: async (iduser: string, category: number) => {
        const response = await api.get(routesWithParams.findUnusedQuestions(iduser, category));
        return response.data.data;
    },

    findAllByCategory: async (category: number) => {
        const response = await api.get(routesWithParams.findAllByCategory(category));
        return response.data.data;
    },

    findAllUnusedByMultipleCategory: async (iduser: string, category: number[]) => {
        const response = await api.get(routesWithParams.findUnusedByMultipleCategory(iduser, category));
        return response.data.data;
    },

    create: async (payload: IQuestionCreatePayload) => {
        const response = await api.post(RoutesWithoutParams.CreateQuestion, payload);
        return response.data.data;
    },

    update: async (idQuestion: number, payload: IQuestionPayload) => {
        const response = await api.put(routesWithParams.updateQuestion(idQuestion), payload);
        return response.data.data;
    },

    destroy: async (idQuestion: number) => {
        const response = await api.delete(routesWithParams.deleteQuestion(idQuestion));
        return response.data.data;
    }
};
